@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
	background: #fff;
	color: #000;
	min-height: 100%;
}

html, body, #root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-family: Roboto, sans-serif;
	font-size: 13px;
	margin: 0;
	padding: 0;
}

body {
	min-height: 100vh;
}
