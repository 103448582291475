.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 13px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 3px 0;
    text-align: left;
    transition: opacity 0.1s ease-out;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.4;
    padding: 3px 16px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item--active,
.react-contextmenu-item--selected {
    color: #fff;
    background-color: #28f;
    text-decoration: none;
}

.react-contextmenu-item--disabled,
.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    color: #888;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}

.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-submenu > .react-contextmenu-item:after {
	content: "\25B6";
	display: inline-block;
	position: absolute;
	right: 7px;
}
