.Disasm {
	flex: 1;
	font-family: Consolas, monospace;
	font-size: 14px;
	min-height: 10em;
	max-height: calc(100% - 25px);
	line-height: 1.26;
	overflow-y: auto;
}

.Disasm--not-started {
	align-items: center;
	display: flex;
	flex: 1 1 auto;
	font-size: larger;
	justify-content: center;
}

.Disasm code {
	font-family: Consolas, monospace;
}

.Disasm__container {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
}

.Disasm__list {
	position: relative;
	text-align: start;
	/* We handle selection. */
	user-select: none;
}

.Disasm .react-contextmenu-wrapper:focus {
	/* Already shown on selection. */
	outline: none;
}

.DisasmLine {
	white-space: nowrap;
}

.DisasmLine__breakpoint-icon {
	padding-left: 2px;
	fill: transparent;
}

.DisasmLine--current {
	/* This uses a linear-gradient to lighten the js-specified background-color value without affecting the text. */
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}

.DisasmLine--selected {
	background: linear-gradient(to bottom, #bcd, #bcd);
}

.DisasmLine--focused {
	background: linear-gradient(to bottom, #39f, #39f);
	color: #fff;
}

.DisasmLine--focused.DisasmLine--cursor {
	background: linear-gradient(to bottom, #28f, #28f);
}

.DisasmLine--breakpoint {
	color: #f00;
}

.DisasmLine--breakpoint .DisasmLine__breakpoint-icon {
	fill: #f00;
}

.DisasmLine--disabled-breakpoint .DisasmLine__breakpoint-icon {
	fill: #999;
}

.DisasmLine--focused.DisasmLine--breakpoint {
	text-shadow: 0 0 2px rgba(255, 255, 255, 0.8);
}

.DisasmLine--focused.DisasmLine--breakpoint .DisasmLine__breakpoint-icon {
	filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.8));
}

.DisasmLine--focused.DisasmLine--disabled-breakpoint .DisasmLine__breakpoint-icon {
	fill: #bbb;
}

.DisasmLine__address {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
	width: 20ex;
}

.DisasmLine__address--nosymbol {
	text-align: center;
}

.DisasmLine__opcode {
	display: inline-block;
	font-weight: bold;
	vertical-align: top;
	width: 12ex;
}

.DisasmLine__opcode::before {
	content: "";
	display: inline-block;
	font-size: 12px;
	position: relative;
	left: -2px;
	width: 1ex;
}

.DisasmLine--current .DisasmLine__opcode::before {
	content: "\25a0";
}

.DisasmLine__params {
	display: inline-block;
	position: relative;
	text-overflow: ellipsis;
	vertical-align: top;
}

.DisasmLine__param--highlighted {
	color: #0ba;
}

.DisasmLine__highlight {
	display: inline-block;
	position: relative;
	z-index: 0;
}

.DisasmLine__highlight--end {
	width: 100%;
}

.DisasmLine__highlight::before {
	display: inline-block;
	content: '';
	background: #8cf;
	border-radius: 1ex;
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	z-index: -1;
}

.DisasmLine--focused .DisasmLine__highlight::before {
	background: #4af;
}

.DisasmBranchGuide {
	position: absolute;
	width: 8px;
}

.DisasmBranchGuide path {
	fill: none;
	stroke: #23f;
	stroke-width: 1px;
}

.DisasmBranchGuide--selected path {
	stroke: #fa7a25;
}
