#NotConnected {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.NotConnected__main {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	font-size: larger;
	justify-content: center;
	max-height: calc(75vh - 24px);
	min-height: 20em;
}

.NotConnected__info {
	font-size: 14px;
	margin-top: 2ex;
}

.NotConnected__form {
	display: block;
	margin-top: 2ex;
}

.NotConnected__uri {
	margin-right: 1ex;
	width: 30ex;
}
