#RegPanel {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 22ex;
}

#RegPanel dl {
	flex: 1 1 auto;
	font-family: Consolas, monospace;
	line-height: 0.8em;
	margin: 0;
	margin-top: 1px;
	height: 0;
	overflow-y: auto;
	text-align: start;
}

#RegPanel dt {
	clear: both;
	cursor: default;
	float: left;
	margin: 0;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 0;
	width: 8ex;
}

#RegPanel dt::before {
	background: #e8efff;
	content: "\00A0";
	display: block;
	float: left;
	margin-left: 1px;
	margin-right: 2px;
	width: 14px;
}

#RegPanel dd {
	border: 1px solid transparent;
	cursor: default;
	margin: 0;
	margin-left: 16px;
	padding: 0;
	padding-left: 8ex;
}

#RegPanel dd:active {
	border-color: #666;
}

.RegPanel__item--selected {
	background: #e0e8ff;
	background-clip: padding-box;
}

.RegPanel__item--changed {
	color: red;
}

#RegPanel .react-contextmenu-wrapper:focus {
	/* Already shown on selection. */
	outline: none;
}

#RegPanel .react-tabs {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

#RegPanel .react-tabs__tab-panel--selected {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
}

/* These fix a bug in IE11. */
.LeftPanel .react-tabs__tab-list {
	flex: 0 0 auto;
}
