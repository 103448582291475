.LeftPanel {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 22ex;
}

.LeftPanel .react-tabs {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.LeftPanel .react-tabs__tab-panel--selected {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.LeftPanel__tools {
	padding: 1ex 0;
}

.LeftPanel__tools button {
	background: transparent;
	border: 0;
	font-size: inherit;
	padding: 0 3px;
	text-align: inherit;
}

.LeftPanel__tools button:enabled {
	cursor: pointer;
}

.LeftPanel__tools button:enabled:active,
.LeftPanel__tools button:enabled:hover,
.LeftPanel__tools button:enabled:focus {
	color: #279;
}
