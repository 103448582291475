.DisasmButtons {
	/* Matches with the Diasm max-height. */
	height: 25px;
}

.DisasmButtons__spacer {
	display: inline-block;
	width: 12px;
}

.DisasmButtons button {
	margin-right: 3px;
}

.DisasmButtons__thread--current {
	font-weight: bold;
}

.DisasmButtons__group {
	display: inline-block;
	padding-bottom: 2px;
	white-space: nowrap;
}

@media only screen and (max-width: 700px) {
	.DisasmButtons {
		height: auto;
		min-height: 25px;
	}
}

@media only screen and (min-width: 701px) {
	.DisasmButtons__nav {
		display: none;
	}
}
