.react-tabs__tab-list {
	border-bottom: 2px solid #3999bd;
	margin: 0;
	padding: 0;
	text-align: start;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 3px 6px;
	cursor: pointer;
}

.react-tabs__tab--selected {
	background: #3999bd;
	color: #fff;
}

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default;
}

.react-tabs__tab:focus {
	background-color: #4cc2ed;
	outline: none;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}

/* Nested tabs... */
.react-tabs__tab-panel .react-tabs__tab-list {
	background: #3999bd;
	border-bottom: 2px solid #fff;
	color: #fff;
}

.react-tabs__tab-panel .react-tabs__tab--selected {
	background: #fff;
	color: #3999bd;
}
