#BreakpointPanelList {
	flex: 1;
	overflow-y: auto;
}

.BreakpointPanelList__no-breakpoints {
	font-size: larger;
	justify-content: center;
	padding: .5em;
	text-align: center;
}

.BreakpointPanelList__table {
	border-collapse: collapse;
	width: 100%;
}

.BreakpointPanelList__table th {
	background: #eee;
	border-bottom: 1px solid #ccc;
	text-align: start;
	padding: 3px;
}

.BreakpointPanelList__table td {
	padding: 2px;
}

.BreakpointPanelList__table-column {
	width: auto;
}

.BreakpointPanelList__table-column-type {
	width: 18ex;
}

.BreakpointPanelList__table-column-offset {
	width: 14ex;
}

.BreakpointPanelList__table-column-break {
	width: 6ex;
}

.BreakpointPanelList__table-column-hits {
	width: 14ex;
}
