#Log {
	background: black;
	border-radius: 1ex;
	color: #ccc;
	flex: 1 1;
	font: 12px Consolas, monospace;
	max-width: 100%;
	min-height: 2em;
	overflow: auto;
	margin: 1ex;
	margin-top: 2px;
	padding: 1ex;
	white-space: pre-wrap;
	text-align: start;
}

.Log__message--internal::before {
	content: "\00BB\00A0";
}

.Log__message__timestamp {
	color: #fff;
}

.Log__message--level-1 {
	color: #0f0;
}

.Log__message--level-2 {
	color: #f00;
}

.Log__message--level-3 {
	color: #ff0;
}

.Log__message--level-4 {
	color: #0ff;
}
