#GPU {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.GPU__main {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	font-size: larger;
	justify-content: center;
	max-height: calc(75vh - 24px);
	min-height: 20em;
}

.GPU__info {
	font-size: 14px;
	margin-top: 2ex;
}

.GPU__form {
	display: block;
	margin-top: 2ex;
}
