#CPU {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.CPU__main {
	display: flex;
	flex: 1;
	max-height: calc(75vh - 24px);
	min-height: 20em;
}

.CPU__pane {
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
}

.CPU__paneClose {
	display: none;
}

@media only screen and (max-width: 700px) {
	.CPU__pane {
		background: #fff;
		display: none;
		position: absolute;
		z-index: 1;
	}

	.CPU__pane--open {
		display: flex;
		height: 75vh;
	}

	.CPU__paneClose {
		display: block;
	}
}
