.GotoBox {
	flex: 0 0 auto;
	padding: 5px;
	padding-top: 2px;
}

.GotoBox__label {
	display: block;
	padding-bottom: 3px;
}

.GotoBox__address {
	width: 11ex;
	margin-right: 4px;
}

.GotoBox__button {
	background: transparent;
	border: 0;
	font-size: inherit;
	padding: 0 3px;
}

.GotoBox__button:enabled {
	cursor: pointer;
}

.GotoBox__button:enabled:active,
.GotoBox__button:enabled:hover,
.GotoBox__button:enabled:focus {
	color: #279;
}
