.App {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	z-index: 0;
}

.App-logo {
	display: inline-block;
	height: 24px;
	margin-right: 5px;
	vertical-align: top;
}

.App-header {
	/* Roughly from PPSSPP's background, maybe replace with something better. */
	background: linear-gradient(135deg, #0d2d3b, #163951 25%, #1c4261 53%, #27517c 89%, #234b72);
	border-bottom: 2px solid #3999bd;
	color: white;
	height: 24px;
	padding: 8px;
	margin-bottom: 3px;
	text-align: right;
}

.App-title {
	display: inline-block;
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: normal;
	margin: 0;
}

.App-nav {
	align-items: center;
	display: flex;
	float: left;
	height: 100%;
	margin: 0;
	padding: 0;
}

.App-nav a {
	color: #fff;
	font-size: 16px;
	margin-right: 2px;
	padding: 10px 1ex;
	text-decoration: none;
}

.App-nav a.active {
	background: #3999bd;
}

.App-nav a:hover,
.App-nav a:active,
.App-nav a:focus {
	background-color: #4cc2ed;
}

.App-utilityPanel {
	display: flex;
	flex: 1;
	max-height: calc(25vh - 24px);
	min-height: 5em;
}

.App-utilityPanel .react-tabs {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.App-utilityPanel .react-tabs__tab-panel--selected {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	min-height: 0;
}
